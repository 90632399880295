import React, {useEffect } from 'react';






const App = () => {

  useEffect(() => {
    window.location.replace("https://api.whatsapp.com/send?phone=919644629469&text=Help%E2%80%A6");
  
  }, []);



  
    
    return (
       <div style={{justify: 'center'}}>
        
       </div>
        
    )
  // const [mobileOpen, setMobileOpen] = React.useState(false);
  // const [products, setProducts] = useState([]);
  // const [cart, setCart] = useState({});
  // const [order, setOrder] = useState({});
  // const [errorMessage, setErrorMessage] = useState('');

  // const fetchProducts = async () => {
  //   const { data } = await commerce.products.list();
  //   setProducts(data);
  // };

  // const fetchCart = async () => {
  //   setCart(await commerce.cart.retrieve());
  // };

  // const handleAddToCart = async (productId, quantity) => {
  //   const item = await commerce.cart.add(productId, quantity);

  //   setCart(item.cart);
  // };

  // const handleUpdateCartQty = async (lineItemId, quantity) => {
  //   const response = await commerce.cart.update(lineItemId, { quantity });

  //   setCart(response.cart);
  // };

  // const handleRemoveFromCart = async (lineItemId) => {
  //   const response = await commerce.cart.remove(lineItemId);

  //   setCart(response.cart);
  // };

  // const handleEmptyCart = async () => {
  //   const response = await commerce.cart.empty();

  //   setCart(response.cart);
  // };

  // const refreshCart = async () => {
  //   const newCart = await commerce.cart.refresh();

  //   setCart(newCart);
  // };

  // const handleCaptureCheckout = async (checkoutTokenId, newOrder) => {
  //   try {
  //     const incomingOrder = await commerce.checkout.capture(checkoutTokenId, newOrder);

  //     setOrder(incomingOrder);

  //     refreshCart();
  //   } catch (error) {
  //     setErrorMessage(error.data.error.message);
  //   }
  // };

  // useEffect(() => {
  //   fetchProducts();
  //   fetchCart();
  // }, []);
  // const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  // return (
  

    
  //   <>
  //   <div style={{ display: 'flex' }}>
  //       <CssBaseline />
        
  //       <Switch>
  //         <Route exact path="/">
  //           <Navbar totalItems={cart.total_items} handleDrawerToggle={handleDrawerToggle} />
  //           <Products products={products} onAddToCart={handleAddToCart} handleUpdateCartQty />
  //         </Route>
  //         <Route exact path="/cart">
  //         <Navbar totalItems={cart.total_items} handleDrawerToggle={handleDrawerToggle} />
  //          <Cart cart={cart} onUpdateCartQty={handleUpdateCartQty} onRemoveFromCart={handleRemoveFromCart} onEmptyCart={handleEmptyCart} />
  //         </Route>
  //         <Route path="/checkout" exact>
  //         <Navbar totalItems={cart.total_items} handleDrawerToggle={handleDrawerToggle} />
  //           <Checkout cart={cart} order={order} onCaptureCheckout={handleCaptureCheckout} error={errorMessage} />
  //         </Route>
  //       </Switch>
  //     </div>

        



  //   <Switch>
    
  //   <Route exact path="/about-us">
  //     <About/>
  //   </Route>
  //   <Route exact path="/shipping-policy">
  //     <ShippingPolicy />
  //   </Route>
  //   <Route exact path="/resetPassword">
  //     <Forget/>
  //   </Route>
  //   <Route exact path="/myTask">
  //     <MyTask/>
  //   </Route>
  //   <Route exact path="/contact">
  //     <Contact/>
  //   </Route>
  //   <Route exact path="/applyRecord">
  //     <ApplyRecord/>
  //   </Route>
  //   <Route exact path="/invite">
  //     <Invite/>
  //   </Route>
  //   <Route exact path="/redenvelope">
  //     <RedEnvelope/>
  //   </Route>
  //   <Route exact path="/financial">
  //     <Financial/>
  //   </Route>
  //   <Route exact path="/bank">
  //     <MyBank/>
  //   </Route>
  //   <Route exact path="/accountSecurity">
  //     <AccountSecurity/>
  //   </Route>
  //   <Route exact path="/accountSecurity/name">
  //     <ModifyName/>
  //   </Route>
  //   <Route exact path="/accountSecurity/password">
  //     <ModifyPassword/>
  //   </Route>
  //   <Route exact path="/accountSecurity/payment">
  //     <ModifyPayment/>
  //   </Route>
  //   <Route exact path="/about-us/about">
  //     <AboutUs/>
  //   </Route>
  //   <Route exact path="/about-us/privacy">
  //     <PrivacyPolicy/>
  //   </Route>
  //   <Route exact path="/terms">
  //     <Terms/>
  //   </Route>
  //   <Route exact path="/refund">
  //     <Refund/>
  //   </Route>
  //   <Route exact path="/cancellation-policy">
  //     <Cancellation />
  //   </Route>
  //   <Route exact path="/about-us/RDA">
  //     <Risk/>
  //   </Route>
  //   <Route exact path="/mypromotion">
  //     <MyPromotion/>
  //   </Route>
  //   <Route exact path="/mypromotion/apply">
  //     <ApplyPromotion/>
  //   </Route>
  //   <Route exact path="/about-us/app-statement">
  //     <AppStatement/>
  //   </Route>
  //   <Route exact path="/address">
  //     <MyAddress/>
  //   </Route>
  //   <Route exact path="/bidHistory/:game">
  //     <FullBidHistory/>
  //   </Route>
  //   <Route exact path="/record/:game">
  //     <FullRecord/>
  //   </Route>
  //   <Route exact path="/promotionRecord">
  //     <PromotionRecord/>
  //   </Route>
  //   <Route exact path="/wallet">
  //     <MyWallet/>
  //   </Route>
  //   <Route exact path="/withdrawal">
  //     <Withdrawal/>
  //   </Route>
  //   <Route exact path="/preOrder/:statusParms?">
  //     <PreOrder/>
  //   </Route>
  //   <Route exact path="/rechargeHistory">
  //     <RechargeHistory/>
  //   </Route>
  //   <Route exact path="/withdrawalHistory">
  //     <WithdrawalHistory/>
  //   </Route>
  //   <Route path="/getRedEnvelop/:id">
  //       <Envelope />
  //   </Route>
    
    
  //   </Switch>
  //     <Switch>
  //     <Route exact path="/wingo">
  //     <Home />
  //     </Route>
      
  //     <Route path="/login/:id">
  //       <AuthForm />
  //     </Route>
  //     <Route path="/login">
  //       <AuthForm />
  //     </Route>
  //     <Route path="/profile">
  //       <Profile />
  //     </Route>
  //   </Switch>
  //   <NavBar/>    
           

    
  //   </>
  
  // );
};

export default App;
